/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
   /** primary **/
   --ion-color-primary: #3880ff;
   --ion-color-primary-rgb: 56, 128, 255;
   --ion-color-primary-contrast: #ffffff;
   --ion-color-primary-contrast-rgb: 255, 255, 255;
   --ion-color-primary-shade: #3171e0;
   --ion-color-primary-tint: #4c8dff;
   /** secondary **/
   --ion-color-secondary: #0cd1e8;
   --ion-color-secondary-rgb: 12, 209, 232;
   --ion-color-secondary-contrast: #ffffff;
   --ion-color-secondary-contrast-rgb: 255, 255, 255;
   --ion-color-secondary-shade: #0bb8cc;
   --ion-color-secondary-tint: #24d6ea;
   /** tertiary **/
   --ion-color-tertiary: #7044ff;
   --ion-color-tertiary-rgb: 112, 68, 255;
   --ion-color-tertiary-contrast: #ffffff;
   --ion-color-tertiary-contrast-rgb: 255, 255, 255;
   --ion-color-tertiary-shade: #633ce0;
   --ion-color-tertiary-tint: #7e57ff;
   /** success **/
   --ion-color-success: #10dc60;
   --ion-color-success-rgb: 16, 220, 96;
   --ion-color-success-contrast: #ffffff;
   --ion-color-success-contrast-rgb: 255, 255, 255;
   --ion-color-success-shade: #0ec254;
   --ion-color-success-tint: #28e070;
   /** warning **/
   --ion-color-warning: #ffce00;
   --ion-color-warning-rgb: 255, 206, 0;
   --ion-color-warning-contrast: #ffffff;
   --ion-color-warning-contrast-rgb: 255, 255, 255;
   --ion-color-warning-shade: #e0b500;
   --ion-color-warning-tint: #ffd31a;
   /** danger **/
   --ion-color-danger: #f04141;
   --ion-color-danger-rgb: 245, 61, 61;
   --ion-color-danger-contrast: #ffffff;
   --ion-color-danger-contrast-rgb: 255, 255, 255;
   --ion-color-danger-shade: #d33939;
   --ion-color-danger-tint: #f25454;
   /** dark **/
   --ion-color-dark: #222428;
   --ion-color-dark-rgb: 34, 34, 34;
   --ion-color-dark-contrast: #ffffff;
   --ion-color-dark-contrast-rgb: 255, 255, 255;
   --ion-color-dark-shade: #1e2023;
   --ion-color-dark-tint: #383a3e;
   /** medium **/
   --ion-color-medium: #989aa2;
   --ion-color-medium-rgb: 152, 154, 162;
   --ion-color-medium-contrast: #ffffff;
   --ion-color-medium-contrast-rgb: 255, 255, 255;
   --ion-color-medium-shade: #86888f;
   --ion-color-medium-tint: #a2a4ab;
   /** light **/
   --ion-color-light: #f4f5f8;
   --ion-color-light-rgb: 244, 244, 244;
   --ion-color-light-contrast: #000000;
   --ion-color-light-contrast-rgb: 0, 0, 0;
   --ion-color-light-shade: #d7d8da;
   --ion-color-light-tint: #f5f6f9;
}

p, ol, ion-row {
   line-height: 1.4;
}

ion-modal {
   --height: auto;
   --width: 40%;
}

.required:after {
   content: " *";
   color: red;
   font-size: larger;
}

ion-menu-button {
   color: #fff;
}

ion-toolbar {
   --background: #6cb33f;
   --color: white;
}

ion-content {
   --background: #cbcbcb;
}

ion-label {
   ion-icon {
      margin-right: 6px;
   }
}

.disclosure {
   background-color: white;
   font-size: small;
   padding: 4px;
}

ion-router-link {
   text-decoration: underline;
   cursor: pointer;
}

ion-footer {
   background-color: #6cb33f;
   padding: 2px 2px 3px 2px;
   font-size: x-small;
   color: white;
   text-align: center;
   background-image: none;

   ion-router-link {
      color: white;
      margin-left: 4px;
      text-decoration: underline;
      cursor: pointer;
   }
}

ion-datetime-button::part(native) {
   background-color: transparent;
   position: absolute;
   top: 24px;
   left: 12px;
}

.input {
   margin-top: 4px;
   margin-bottom: 1px;
   width: 100%;
   border: lightgray 1px solid;
   background-color: aliceblue;
   text-align: left;
   align-content: flex-start;
   align-items: flex-start;
   height: 2.5em;

   &.calendar-input {
      width: 11em;

      ion-datetime-button::part(native) {
         padding-top: .9em;
         padding-left: 0;
         background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA8UExURcDDyUBAQEFBQVZXWKmtspSXm6mssdfc49bb4ldYWdXa4cDEyqqus5WYm5WYnGttb9jd42hqbFNUVQAAAIrQZKMAAAAUdFJOU /////////////////////////8AT0/nEQAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAF5JREFUKFOlzlEOgCAMA1AYKqAi6u5/V+dWCYl/2q/1JTQ4fuUHOU9dwiA0oiCTUAwomqBbKaNKcrL5ua35ReclK4RuMeJiUrTgX5u89dVu0F6JIA9xPE5cjbp8JOYLMJoR14fCnroAAAAASUVORK5CYII=');
         background-position-x: .5em;
         background-position-y: .9em;
         background-repeat: no-repeat;
         left: 0;
         width: 11em;
      }
   }
}

ul.list {
   list-style-type: disc;

   li {
      padding-bottom: .3rem;
   }
}

ion-list-header {
   --background: linear-gradient( 90deg, rgba(7, 166, 104, 1) 0%, rgba(11, 91, 59, 1) 81% );
}

ion-progress-bar {
   height: 20px;
}

.toolbar-header {
   --background: rgba(11, 91, 59, 1);
}

ion-item {
   margin-top: 8px;
   margin-bottom: 8px;
}

.tight {
   margin-top: 0;
   margin-bottom: 0;
}

.toolbar-logo {
   padding: 5px;
}

.full-button {
   width: 100% !important;
   border-radius: 4px;
   min-height: 35px;
}

input.native-input.sc-ion-input-md,
ion-select.md.in-item,
ion-textarea.sc-ion-textarea-md-h.sc-ion-textarea-md-s.md {
   margin-top: 4px;
   margin-bottom: 1px;
   padding-left: 4px;
   border: lightgray 1px solid;
   background-color: aliceblue;
}

.amount {
   float: right;
}

.right-aligned {
   text-align: right;
}

.center-aligned {
   text-align: center !important;
}

.bold-and-centered {
   text-align: center;
}

.bold-and-centered h2 {
   font-weight: bold;
   font-size: 18pt;
}

.row-text {
   font-size: 14px;
}

.progress-tracker-label {
   h3 {
      font-weight: bold;
   }
}

.full-center {
   width: 100%;
   text-align: center;
}

.clickable {
   pointer-events: auto !important;
}

.spinner {
   height: 15px !important;
}

.icon {
   width: 35px;
   height: 35px;
}

ion-card.resources {
   img {
      margin: 5px auto;
      height: 30vh;
      width: auto !important;
      max-width: 95%;
      align-items: center;
   }
}

.white {
   color: white;
}

.fab-button {
   margin-right: 15px;
   height: 25px;
   width: 25px;
}

.lender-button {
   --border-color: #008752;
   --background: transparent;
   --color: #008752;
   --color-focused: #008752;
   margin-top: 0;
}

.arrow-color {
   color: #414141;
}

ion-col {
   --ion-grid-column-padding: 0;
}

.margin-top {
   margin-top: 10px;
}

.white-background {
   background-color: white;
}

.back-button {
   height: 35px;
   width: 35px;
   color: white;
}

.chart-div {
   position: relative;
   margin: auto;
   width: 80vw;
   min-height: 300px;
}

.chart-div canvas {
   width: 100% !important;
}

.no-margin-top {
   margin-top: 0;
   padding-top: 0;
}

.height {
   height: 200px;
}

.social-media-button {
   width: 40px;
   font-size: 32px;
}

ion-icon {
   color: white;
}

.social-media-grid {
   --ion-grid-width: 200px;
}

.social-media-column {
   --ion-grid-column-padding: 0;
   --ion-grid-columns: 6;
}

.clickable-card {
   cursor: pointer;
}

.resources-column {
   display: flex;
   justify-content: center;
}

.lender-flex {
   display: flex;
   justify-content: center !important;
   align-items: stretch !important;

   .ion-text-wrap {
      flex-shrink: 3;
   }

   div.item-inner {
      padding-right: 0;
   }
}

.lender-progress-holder {
   padding: 0;
   height: auto;
   flex-grow: 2;
}

.welcome-banner {
   --background: linear-gradient( 90deg, rgba(7, 166, 104, 1) 0%, rgba(11, 91, 59, 1) 81% );
   background: linear-gradient( 90deg, rgba(7, 166, 104, 1) 0%, rgba(11, 91, 59, 1) 81% );
   margin-top: 0;
   margin-bottom: 0;
}

.welcome-image {
   height: 100px !important;
   width: 100px !important;
   margin-top: auto;
   margin-bottom: auto;
   vertical-align: middle;
   padding: 5px;
}

.welcome-image-right {
   height: 100px !important;
   width: 100px !important;
   margin-top: auto;
   margin-bottom: auto;
   vertical-align: middle;
   padding: 5px;
   text-align: right !important;
}

.banner-text {
   .ion-text-wrap {
      font-size: 35px !important;
      color: white !important;
      text-align: center;
      font-family: "Merriweather", serif;
   }
}

.flex-grid {
   display: flex;
   flex-flow: row nowrap;
   justify-content: space-around;
}

.flex-grid {
   div {
      flex: 4;

      &:first-child {
         text-align: left;
      }

      &:last-child {
         text-align: right;
      }

      &:nth-child(2) {
         text-align: center;
      }

      &.single-card-grid {
         text-align: right !important;
         flex: 6 !important;
      }
   }
}

#sharpenChat.light {
   display: none;
}

.usesIris {

   #sharpenChat {
      &.light {
         display: block;
         position: fixed !important;
         right: 5rem !important;
      }

      #chatEntryWindow {
         height: 0px !important;
         min-height: 0px !important;
      }

      #chatWindow {
         height: 0px !important;
         min-height: 0px !important;

         &.expanded {
            height: 50vh !important;
            min-height: 300px !important;
         }
      }
   }

   #openChatBtn {
      position: relative;

      &:before {
         width: 90px;
         height: 90px;
         bottom: 0.8rem;
         content: "";
         background-size: contain;
         background-image: url(https://www.apprisen.com/wp-content/themes/apprisen/assets/images/iris-help.png);
         background-repeat: no-repeat;
         display: inline-block;
         z-index: 1000;
         position: absolute;
         right: -5em;
      }

      &:after {
         bottom: 2rem;
         height: 2.8rem;
         padding: 0 1.3rem 0 0;
         text-align: center;
         width: 185px;
         content: "Chat with IRIS";
         background-color: #ffffff;
         border-radius: 0.9375rem;
         position: absolute;
         right: -1rem;
         z-index: 999;
         line-height: 2.8;
         border: 1px solid lightgray;
      }

      &.collapse {
         &:after {
            display: none;
         }
      }
   }

   #mainChatIcon,
   #chatHoverMsg {
      display: none;
   }

   p.withIris {
      min-height: 3.9rem;
   }
}

.atlwdg-trigger {
   position: fixed;
   background: #205081;
   padding: 5px;
   border: 2px solid white;
   border-top: none;
   font-weight: bold;
   color: white !important;
   display: block;
   white-space: nowrap;
   text-decoration: none !important;
   font-family: Arial, Helvetica, sans-serif;
   font-size: 12px;
   -webkit-box-shadow: 0 0 5px rgb(0,0,0);
   box-shadow: 0 0 5px rgb(0,0,0);
   border-radius: 0 0 5px 5px;
   width: 7rem;
}

.atlwdg-trigger.atlwdg-SUBTLE {
   left: 0;
   bottom: 1.1rem;
   border: 1px solid #ccc;
   border-bottom: none;
   border-right: none;
   background-color: #f5f5f5;
   color: #444 !important;
   font-size: 11px;
   padding: 6px;
   -webkit-box-shadow: -1px -1px 2px rgb(0,0,0);
   box-shadow: -1px -1px 2px rgb(0,0,0);
   border-radius: 2px 0 0 0;
   position: absolute;
}

.overview-grid {
   display: "grid";
   grid-template-columns: 50% 50%;
   grid-template-rows: auto auto;
   grid-auto-flow: row dense;

   .left-column {
      grid-column: 1;
      grid-row: 1;
   }

   .right-column {
      grid-column: 2;
      grid-row: 1/3
   }
}

.loader {
   width: 20%;
   height: 20%;
}

@media (max-width: 992px) {
   .overview-grid {
      display: "grid";
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-auto-flow: row dense;

      .left-column {
      }

      .right-column {
         grid-column: 1;
         grid-row: 2
      }
   }
}

@media (max-width: 805px) {
   .loader {
      width: 40%;
      height: 40%;
   }

   .banner-text {
      .ion-text-wrap {
         font-size: 30px !important;
         color: white !important;
         text-align: center;
      }
   }

   ion-modal {
      --width: 100%;
   }
}

@media (max-width: 768px) {
   ion-card.resources {
      img {
         margin: 5px auto;
         height: 25vh !important;
         width: auto !important;
         max-width: 95%;
         align-items: center;
      }
   }


   .sc-ion-label-md-s {
      h1 {
         font-size: 20px;
      }
   }

   .lender-flex {
      /*.ion-text-wrap {
         width: 50% !important;
         flex-shrink: 4;
         text-align: left;
      }*/

      h3 {
         font-size: 12pt !important;
      }
   }

   .flex-grid {
      div {
         &:first-child {
            flex: 3 !important;
         }

         &:last-child {
            flex: 3 !important;
         }

         &:nth-child(2) {
            flex: 6 !important;
         }
      }
   }

   .welcome-image {
      height: 50px !important;
      width: 50px !important;
      margin-top: auto;
      margin-bottom: auto;
      vertical-align: middle;
      padding: 2px;
   }

   .welcome-image-right {
      height: 75px !important;
      width: 75px !important;
      margin-top: auto;
      margin-bottom: auto;
      vertical-align: middle;
      padding: 5px;
      text-align: right !important;
   }
}

@media (max-width: 553px) {
   .atlwdg-trigger.atlwdg-SUBTLE {
      bottom: 1.8rem;
   }

   .usesIris {
      #openChatBtn {
         &:before {
            width: 70px !important;
            height: 70px !important;
            bottom: 1.5rem;
         }

         &:after {
            bottom: 3rem;
            height: .6rem;
            padding: 0.4rem 0 0.3rem 0;
            text-align: center;
            width: 120px;
            z-index: 999;
            line-height: .5;
            font-size: 12px;
            right: -1.2rem;
            border: 1px solid lightgray;
         }
      }
   }
}

@media (max-width: 450px) {
   .chart-div {
      height: 60vh !important;
   }
}

@media (max-width: 360px) {
   .banner-text {
      .ion-text-wrap {
         font-size: 24px !important;
      }
   }

   .progress-tracker-label {
      &.center-aligned {
         padding-left: .5rem;
         padding-right: .5rem;
      }

      ion-label {
         h3 {
            font-size: 12px;
         }

         div {
            font-size: 12px;
         }
      }
   }
}
